import React from "react"
import PageRoot from "../../components/PageRoot"
import { List } from "antd"
import ContainerWithPadding from "../../components/ContainerWithPadding"
import { RightOutlined } from "@ant-design/icons"
import { navigate } from "gatsby"
import i18n from "i18next"
import { useTranslation } from "react-i18next"

const menuList = [
  {
    key: "/me/profile",
    name: i18n.t("menu:settings"),
    description: i18n.t("menu:settingsDescription"),
  },
  {
    key: "/help",
    name: i18n.t("menu:helpDesk"),
    description: i18n.t("menu:helpDeskDescription"),
  },
  {
    key: "/me/billing/payment-method/list",
    name: i18n.t("menu:billing"),
    description: i18n.t("menu:billingDescription"),
  },
  {
    key: "/me/billing",
    name: i18n.t("menu:billingHistory"),
    description: i18n.t("menu:billingHistoryDescription"),
  },
  {
    key: "/me/subscription",
    name: i18n.t("menu:subscription"),
    description: i18n.t("menu:subscriptionDescription"),
  },
  {
    key: "/auth/sign-out",
    name: i18n.t("menu:signOut"),
    description: i18n.t("menu:signOutDescription"),
  },
]

const ProfilePage = () => {
  const { t } = useTranslation()
  return (
    <PageRoot title={t("label:myAccount")} showTitle>
      <ContainerWithPadding size={"small"}>
        <List
          className="user-actions"
          dataSource={menuList}
          renderItem={({ key, name, description }, index) => (
            <List.Item
              extra={<RightOutlined />}
              onClick={() => {
                navigate(key)
              }}
            >
              <div className="profile-menu-items">
                <h1>{name}</h1>
                <p className="secondary">{description}</p>
              </div>
            </List.Item>
          )}
        />
      </ContainerWithPadding>
    </PageRoot>
  )
}

export default ProfilePage
